import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from '../components/layout'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost (
        sort: {
          fields: publishedDate,
          order: DESC
        }
      ){
        edges {
          node {
            title
            slug
            publishedDate(fromNow:true)
          }
        }
      }
    }
  `)
  const { allContentfulBlogPost: { edges }} = data
  return ( 
    <Layout>
      <h1>Blog</h1>
      <ul>
        {edges.map(({ node }) => (
          <li key={node.slug}>
            <h3><Link to={`/blog/${node.slug}`}>{node.title}</Link></h3>
            <p>{node.publishedDate}</p>
          </li>
        ))}
      </ul>
    </Layout>
  )
 }